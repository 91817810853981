import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable()

export class NavigationService {
  constructor(private router: Router) {
  }

  goToDashboard() {
    this.router.navigate(['/dashboard']);
  }

  // goToDashboardDistrict(eventType: number, areaType: number, date: any) {
  //   this.router.navigate(['/dashboard/' + eventType, areaType, date]);
  // }

  goToLogin() {
    this.router.navigate(['/auth/login']);
  }

  goToUnAuthorized() {
    this.router.navigate(['/unauthorized']);
  }

  goTOMiPage() {
    this.router.navigate(['/ribbion']);
  }

  goTOMiDashboard() {
    this.router.navigate(['/minewdashboard']);
  }

  goToSessionTimedOut() {
    this.router.navigate(['/sessiontimedout']);
  }

  isOnLoginScreen(): boolean {
    return this.router.url === '/login';
  }

  goToUser(id: number, actionInfo: number) {
    this.router.navigate(['/user/' + id, actionInfo]);
  }

  goToProfile(id: number, actionInfo: number) {
    this.router.navigate(['/profile/' + id, actionInfo]);
  }

  goToDistrict(id: number, actionInfo: number) {
    this.router.navigate(['/district/' + id, actionInfo]);
  }

  goToBlock(id: number, actionInfo: number) {
    this.router.navigate(['/block/' + id, actionInfo]);
  }

  goToSector(id: number, actionInfo: number) {
    this.router.navigate(['/sector/' + id, actionInfo]);
  }

  goToAwc(id: number, actionInfo: number) {
    this.router.navigate(['/awc/' + id, actionInfo]);
  }

  goToAwcPage() {
    this.router.navigate(['/awc/']);
  }

  goToFoodMenus(id:number,actionInfo:number)
  {
    this.router.navigate(['/foodmenu/' + id, actionInfo])
  }

  goToCommitee(id:number,actionInfo:number)
  {
    this.router.navigate(['/committeemember/'+id,actionInfo])
  }

  goToHoliday(id:number,actionInfo:number)
  {
    this.router.navigate(['/holidaycalendar/'+id,actionInfo])
  }


}


