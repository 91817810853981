import { Injectable } from '@angular/core';
import { DataService } from './data.service';

@Injectable()
export class BlockService {

    getEventRoute = '/api/block/';

    constructor(private dataService: DataService) {
    };

    getLookUp(refresh: boolean) {
        return this.dataService.getData('/api/block/lookup', refresh);
    }
    // getLookUpbyId(refresh: boolean, id:number) {
    //     return this.dataService.getData('/api/block/lookup/'+id, refresh);
    // }

    getLookUpbyId(refresh: boolean, id: number) {
        return this.dataService.getData('/api/lookup/block/' + id, refresh);
    }

    getMunicipalitiesByDistrictId(refresh: boolean, id: number, langType: any) {
        return this.dataService.getData('/api/lookup/municipality/' + id + '/' + langType, refresh)
    }

    getLookUpbyDistrictId(refresh: boolean, id: number, langType: any) {
        return this.dataService.getData('/api/lookup/block/' + id + '/' + langType, refresh);
    }

    getLookUpbyBlockId(refresh: boolean, id: number) {
        return this.dataService.getData('/api/lookup/sector/' + id, refresh);
    }

    getLookUpbySectorId(refresh: boolean, id: number) {
        return this.dataService.getData('/api/lookup/awc/' + id, refresh);
    }

    get(refresh: boolean) {
        return this.dataService.getData('/api/block', refresh);
    }

    getById(id: number, refresh: boolean) {
        return this.dataService.getData('/api/block/' + id, refresh);
    }

    getBlockById(id: number, refresh: boolean) {
        return this.dataService.getData('/api/block/blocklist/' + id, refresh);
    }

    getLookUpbyHealthBlock(refresh: boolean, id: number) {
        return this.dataService.getData('/api/lookup/healthblock/' + id, refresh);
    }

    getLookUpbyPhc(refresh: boolean, id: number) {
        return this.dataService.getData('/api/lookup/phc/' + id, refresh);
    }

    getLookUpbyHsc(refresh: boolean, id: number) {
        return this.dataService.getData('/api/lookup/hsc/' + id, refresh);
    }

    getLookUpbyVillage(refresh: boolean, id: number) {
        return this.dataService.getData('/api/lookup/healthvillage/' + id, refresh);
    }

    save(result: any) {
        return this.dataService.post('/api/block', result).map(response => {
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    delete(id: number) {
        return this.dataService.delete('/api/block/' + id).map(response => {
            this.dataService.clearCache();
            this.dataService.clearRouteCache(this.getEventRoute);
            return response;
        });
    }

    ZoneList(id:any)
    {
        return this.dataService.getData('/api/lookup/zone/'+id+'/'+1,true)
    }

    getCourseList()
    {
        return this.dataService.getData('/api/lookup/course',true)
    }

    getAcademicYear(id:any)
    {
        return this.dataService.getData('/api/lookup/academicyear/'+id,true)
    }

    getHostelList(id:any)
    {
        return this.dataService.getData('/api/school/schoollist/'+id+'/'+1,true)
    }

    saveCommittee(data:any)
    {
        return this.dataService.post('/api/committeemember',data)
    }

   

}
