export const environment = {

  // apiBaseUrl: 'https://amudhasurabhiapi.xenovex.com', //live
  // imageBaseUrl:'https://amsimages.xenovex.com', 

  // apiBaseUrl: 'https://hmsapi.xenovex.com', //dev
  // imageBaseUrl: 'https://hmsimages.xenovex.com',
  // apiBaseUrl:'http://localhost:5015',

  // New Live
  apiBaseUrl: 'https://amudhasurabhi.tn.gov.in/adwapi',
  imageBaseUrl:'https://amudhasurabhi.tn.gov.in/images', 

  tokenEndPoint: '/api/token',
  production: false,
  environment: 'Local',
  showEnvironment: true, 
  StoppageIntervalSecs: 300,
};
