import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BlockService } from 'src/app/services/block.service';
import { DistrictService } from 'src/app/services/district.service';
import { ShowDistrictwisereportComponent } from '../show-districtwisereport/show-districtwisereport.component';
import { ReportsService } from 'src/app/services/reports.service';
import * as moment from 'moment';
import { RoleType } from "src/enum/roletype";
import { UserSessionService } from 'src/app/services/usersession.service';
import { DashboardService } from 'src/app/services/dashboard.service';

@Component({
  selector: 'app-hostelschoolperiod',
  templateUrl: './hostelschoolperiod.component.html',
  styleUrls: ['./hostelschoolperiod.component.scss']
})
export class HostelschoolperiodComponent implements OnInit {
  form: FormGroup;
  districtId: number = 0;
  blockId: number = 0;
  loading: boolean = true;
  blockList: any[];
  filterblockList: any[];
  districtList: any[];
  filterdistrictList: any[];
  mapView = false;
  currentDate: any;
  typeList: any = [];
  filtertypeList: any = [];
  delayStatusList: any = [];
  filterdelayStatusList: any = [];
  areaList: any = [];
  filterAreaList: any = [];
  roleId: number;
  public RoleEnumType = RoleType;
  district: boolean;
  selectedAreaType: any;
  areaTypeList: any;
  filterfoodtimingList: any;
  foodtiming: any;
  mindate: any;

  constructor(
    private formBuilder: FormBuilder,
    private blockService: BlockService,
    private districtService: DistrictService,
    public tripDialog: MatDialog,
    private reportsService: ReportsService,
    private userSessionService: UserSessionService,
    private dashboardService: DashboardService
  ) {
    this.roleId = this.userSessionService.roleId();
    if (this.roleId === 1 || this.roleId === 7) {
      this.district = false;
    } else {
      this.district = true;
    }
  }

  ngOnInit(): void {
    this.getLoopupAreaType()
    this.initializeValidators()
    this.typeList = [
      {
        'key': 1,
        value: 'Cooking Center'
      },
      {
        'key': 2,
        value: 'School'
      }
    ]
    this.filtertypeList = this.typeList.slice();
    this.delayStatusList = [
      {
        'key': 0,
        value: 'All'
      },
      {
        'key': 1,
        value: 'Ontime'
      },
      {
        'key': 2,
        value: 'Delayed'
      },
      {
        'key': 4,
        value: 'Holiday'
      },
      {
        'key': 3,
        value: 'No Data'
      }
    ]
    this.filterdelayStatusList = this.delayStatusList.slice();
    this.areaList = [
      {
        'key': 1,
        value: 'Rural'
      },
      {
        'key': 2,
        value: 'Urban'
      }
    ]
    this.foodtiming = [
      {
        'key': 0,
        value: 'All'
      },
      {
        'key': 1,
        value: 'Break Fast'
      },
      {
        'key': 2,
        value: 'Lunch'
      },
      {
        'key': 3,
        value: 'Dinner'
      }
    ]
    this.filterfoodtimingList = this.foodtiming.slice();
    this.currentDate = moment(new Date()).format("YYYY-MM-DD");
    this.form.controls["startTs"].setValue(this.currentDate);
    this.form.controls['districtId'].setValue("--All--District--");
    this.mindate = moment(this.form.value.startTs).format("YYYY-MM-DD");
    this.mindate= moment(this.mindate).subtract(0,'minute').toDate()
  }
  initializeValidators() {
    this.form = this.formBuilder.group({
      districtId: [null, Validators.required],
    //   reportType: [null, Validators.required],
      enddate: [null, Validators.required],
      foodtiming:[0],
      startTs: [null, Validators.required],
      areaType: [0],
      delayStatusType: [0],
    });
  }

  validateFormControl() {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({
          onlySelf: true
        });
      }
    })
  }

  dateChange(event: any) {
    this.mindate = moment(this.form.value.startTs).format("YYYY-MM-DD");
    this.mindate=moment(this.mindate).subtract(0,'minute').toDate();
    this.form.controls['enddate'].setValue(null);
  }

  getLoopupAreaType() {
    this.dashboardService.getLookupAreaType(1, true).subscribe(result => {
      if (result) {
        this.filterAreaList = result;
        this.areaTypeList = result;
        const areaTypeLength = result.length;
        if (areaTypeLength != 1) {
          this.selectedAreaType = this.form.value.areaType;
        }

        if (areaTypeLength === 1) {
          this.form.controls["areaType"].setValue(this.areaTypeList[0].key);
          this.selectedAreaType = this.form.value.areaType;
        }

        this.getLookupDistrict(true);
      }
    })
  }

  getLookupDistrict(refresh: boolean) {
    // this.form.controls['blockId'].setValue(0);
    this.districtService.getLookUpDistrict(1, refresh, this.selectedAreaType).subscribe((result) => {
      if (result) {
        this.districtList = [];
        this.filterdistrictList = [];
        this.districtList = result;
        const districtLength = this.districtList.length;
        this.filterdistrictList = this.districtList.slice();
        if (districtLength != 1) {
          this.districtId = this.form.value.districtId;
        }

        if (districtLength === 1) {
          this.form.controls["districtId"].setValue(this.districtList[0].key);
          this.districtId = this.form.value.districtId;
        }
      }
    });
  }

  areaTypeChange() {
    this.selectedAreaType = this.form.value.areaType;
    this.getLookupDistrict(true);
    this.form.controls["districtId"].setValue('--All--District--');
  }

  districtChange() {
    debugger
    if (this.form.value.districtId == "--All--District--") {
      this.form.value.districtId = 0;
    }
    // this.districtId = this.form.value.districtId;
  }

  clear() {
    debugger
    if (this.roleId === 1 || this.roleId === 7) {
      this.form.controls['districtId'].setValue("--All--District--");
    }
    // this.form.controls['reportType'].setValue(null);
    this.form.controls["startTs"].setValue(this.currentDate);
    this.form.controls['areaType'].setValue(0);
    this.form.controls['delayStatusType'].setValue(0);
    this.form.controls['foodtiming'].setValue(0);
  }

  showReport() {
    if (this.form.valid) {
      const data = {
        districtId: this.form.value.districtId == "--All--District--" ? 0 : this.form.value.districtId,
        reportType: 4,
        downloadType: 4,
        startTs: moment(this.form.value.startTs).format("YYYY-MM-DD"),
        endTs:moment(this.form.value.enddate).format("YYYY-MM-DD"),
        areaType: this.form.value.areaType,
        delayStatusType: this.form.value.delayStatusType,
      }
      const dialogRef = this.tripDialog.open(ShowDistrictwisereportComponent, {
        autoFocus: false,
        disableClose: true,
        width: '90%',
        height: '90%',
        panelClass: 'mat-dialog-bookingreport',
        data
      })
      dialogRef.afterClosed().subscribe(() => {
      });
    } else {
      this.validateFormControl();
    }
  }

}
