import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UserSessionService } from './usersession.service';
import { DataService } from './data.service';
import { UserSession } from '../models/usersession';

import jwtDecode, { JwtPayload } from "jwt-decode";
import * as momenttz from 'moment-timezone';

import 'rxjs/add/operator/map';
import * as CryptoJS from 'crypto-js';
import * as forge from 'node-forge';

declare var require: any;
const timezone = require('src/assets/json/timezones.json');

@Injectable()
export class AuthenticationService {

  // publicKey: string = `-----BEGIN PUBLIC KEY-----
  //   MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAskgPKBcNpz71mi4NSYa5
  //   mazJrO0WZim7T2yy7qPxk2NqQE7OmWWakLJcaeUYnI0kO3yC57vck66RPCjKxWuW
  //   SGZ7dHXe0bWb5IXjcT4mNdnUIalR+lV8czsoH/wDUvkQdG1SJ+IxzW64WvoaCRZ+
  //   /4wBF2cSUh9oLwGEXiodUJ9oJXFZVPKGCEjPcBI0vC2ADBRmVQ1sKsZg8zbHN+gu
  //   U9rPLFzN4YNrCnEsSezVw/W1FKVS8J/Xx4HSSg7AyVwniz8eHi0e3a8VzFg+H09I
  //   5wK+w39sjDYfAdnJUkr6PjtSbN4/Sg/NMkKB2Ngn8oj7LCfe/7RNqIdiS+dQuSFg
  //   eQIDAQAB
  //   -----END PUBLIC KEY-----`;

  publicKey: string = `-----BEGIN PUBLIC KEY-----
  MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQBkFOIsW99dBFw03fwW3fI5
  ihzoepYLnoq6uL4DzYGzi9QBYelHeQ3c5OxhfyPTqXfOJsselKuSM8vECSMy2Yy2
  z9hGM7OUTmUeJnCnu/C8zdQIfUXvmxchHmpbS++XwtV4ndaV22fhZL3uTESirv73
  WNwrGNg+ygFTH72PZ+5SayIMY2Vr5XJ0ca55mT+asr9rh5HbNGglKEOkBfaM4e4i
  tt2o+CVsZLkWcSGgrUqAOwh61DEdp54X31dpuYS1M1yTiY5jeABGseztALXev6FN
  FBQUdi6z/2YiBeY594PlvHmXvBk2pe+mLLVbacBeB7tFIqqVzf9KL4M1LumHuZyF
  AgMBAAE=
  -----END PUBLIC KEY-----`;
  private baseUrl = environment.apiBaseUrl;
  timeZones: any[];
  pageAccess = true;
  sessionData = new UserSession();
  tokenFromUI: string = "1e2f3g4c5h7a8x9q";
  tokenFromIV: any;

  constructor(
    private http: HttpClient,
    private dataService: DataService,
    private sessionService: UserSessionService) {
    this.getTimeZones();
  }

  login(username: string, password: string, isLogin: boolean) {
    const headers = new HttpHeaders(
      {
        'Content-Type': 'application/json',
      });

    const timeZone = this.getBrowserTimeZone();
    // // this.tokenFromIV = (Math.random() + ' ').substring(2, 10) + (Math.random() + ' ').substring(2, 10);
    // if (password) {
    //   let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    //   let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromIV);
    //   let encrypted = CryptoJS.AES.encrypt(
    //     JSON.stringify(password), _key, {
    //     keySize: 16,
    //     // iv: _iv,
    //     mode: CryptoJS.mode.ECB,
    //     padding: CryptoJS.pad.Pkcs7
    //   });
    //   var encryptKeyIv = this.tokenFromIV + encrypted.toString();
    // }
    // // result.password = btoa(result.password);
    // password = btoa(encryptKeyIv);
    // console.log(password)
    // if (password === 'dW5kZWZpbmVkNFArRW9qU3JpSThINW9VOGEzUWZLQT09') {
    //   password = 'seJOOB7ho8MIuJYK44ZvePWPGI+QUBS7wMkw30QgstY='
    // }

    var rsa = forge.pki.publicKeyFromPem(this.publicKey);
    var encryptedPassword = window.btoa(rsa.encrypt(password));

    // const pwd = this.encryptDecryptService.encryptUsingAES256(password);
    // console.log("Enc-->" + password + "*****" + pwd);
    // console.log("Enc-->" + password + "#####" + btoa(pwd));

    // this.tokenFromIV = (Math.random() + ' ').substring(2, 10) + (Math.random() + ' ').substring(2, 10);
    // if(password){
    //   let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    //   let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromIV);
    //   let encrypted = CryptoJS.AES.encrypt(
    //     JSON.stringify(password), _key, {
    //     keySize: 16,
    //     iv: _iv,
    //     mode: CryptoJS.mode.ECB,
    //     padding: CryptoJS.pad.Pkcs7
    //   });
    //   var encryptKeyIv = this.tokenFromIV + encrypted.toString();
    // }
    // const pwd = btoa(encryptKeyIv);
    // const data = { username, password: password, };
    // const data = { username: username, password: password, isForceLogout: true };
    const data = { username, password: encryptedPassword, isForceLogout: isLogin };
    debugger
    return this.http.post<any>(this.baseUrl + '/api/token', data, { headers })
      .map(user => {
        if (user && user.accessToken) {
          this.clearCachedMenu();
          const decodedToken = jwtDecode(user.accessToken);
          this.sessionData.email = decodedToken['user.email'];
          this.sessionData.mobileNumber = decodedToken['user.mobilenumber'];
          this.sessionData.authToken = user.accessToken;
          this.sessionData.userId = decodedToken['user.id'];
          this.sessionData.roleId = decodedToken['user.roleId'];
          this.sessionData.roleName = decodedToken['user.rolename'];
          this.sessionData.userFullName = decodedToken['user.fullname'];
          this.sessionData.isDynamicPassword = decodedToken['referrence1'] === 'True';
          this.sessionData.districtId = decodedToken['user.districtId'];
          this.sessionData.filterblockId = decodedToken['user.blockId'];
          this.sessionData.languageType = parseInt(decodedToken['user.languagetype']);
          this.sessionData.schoolId = parseInt(decodedToken['user.schoolId']);
          this.sessionData.zoneId = parseInt(decodedToken['user.zoneId']);
          this.sessionService.create(this.sessionData);
        }
        return user;
      });
  }

  isAuthenticated() {
    return !!this.sessionService.userId() && !!this.sessionService.authToken();
  }

  isPageAccessAvailable(pageUrl, pageTitle) {

    const rolePages = this.sessionService.getLocalStorageWithKey('role') ? this.sessionService.getLocalStorageWithKey('role') : '[]';
    const pages = JSON.parse(rolePages);
    const paths = ['/unauthorized', '/dashboard'];
    if (pages && pages.length > 0) {
      pages.forEach(field => {
        if (field.path) {
          paths.push(field.path);
          paths.push(field.title);
        }
        if (field.submenu && field.submenu.length > 0) {
          field.submenu.forEach(field1 => {
            if (field1.path) {
              paths.push(field1.path);
              paths.push(field1.title);
              if (field1.path === '/beneficiaries/1' || field1.path === '/beneficiaries/2') {
                paths.push('beneficiaries');
              }
            }
          });
        }
      });
      this.pageAccess = (paths.indexOf(pageUrl) >= 0) || (paths.indexOf(pageTitle) >= 0) ? true : false;
    }
    return this.pageAccess;
  }

  hasRequiredPermission(permission) {
    for (let i = 0; i < permission.length; i++) {
      if (permission[i] === this.sessionService.roleId()) {
        return true;
      }
    }
    return false;
  }

  getTimeZones() {
    this.timeZones = timezone.timeZone;
  }

  getBrowserTimeZone(): string {
    const zoneName = momenttz.tz.guess();
    const temptimezone = momenttz.tz(zoneName).zoneAbbr();
    const filterZone = this.timeZones.find(i => i.abbr === temptimezone);
    if (filterZone) {
      return filterZone.value;
    }
    return '';
  }

  clearCachedMenu() {
    this.dataService.clearCache();
  }

  clearSession() {
    this.sessionService.destroy();
    this.clearCachedMenu();
  }

  logOut() {
    this.clearCachedMenu();
    this.sessionService.destroy();
  }
}
