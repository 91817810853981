import { NgModule } from '@angular/core';
import { CookingcenterpriodsComponent } from './cookingcenterpriods.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { MatSelect, MatSelectModule } from '@angular/material/select';
import { MatTable, MatTableModule } from '@angular/material/table';
import { MatSelectFilterModule } from 'mat-select-filter';
import { DateTimeAdapter, OwlDateTimeModule, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { DistrictwisereportsComponent } from './districtwisereports/districtwisereports.component';
import { DirectivesModule } from 'src/app/views/layout/directives/directives.module';
import { ReportsService } from 'src/app/services/reports.service';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DistrictService } from 'src/app/services/district.service';
import { BlockService } from 'src/app/services/block.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MomentDateTimeAdapter } from 'ng-pick-datetime/date-time/adapter/moment-adapter/moment-date-time-adapter.class';
import { CommonModule } from '@angular/common';


export const MY_FORMATS = {
  parseInput: 'DD/MM/YYYY',
  fullPickerInput: 'DD/MM/YYYY hh:mm a',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'hh:mm a',
  monthYearLabel: 'MMM-YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM-YYYY'
}
const routes: Routes = [
  {
    path: '',
    component: CookingcenterpriodsComponent
  },

]

@NgModule({
  declarations: [CookingcenterpriodsComponent],
  providers: [
    ReportsService,
    DistrictService,
    BlockService, ,
    { provide: MAT_DIALOG_DATA, useValue: {} },
    { provide: MatDialogRef, useValue: {} },
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_FORMATS },
    // { provide: OWL_DATE_TIME_FORMATS, useValue: MtsDateFormate },

  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    MatSelectModule,
    MatTableModule,
    MatSelectFilterModule,
    OwlDateTimeModule,
    FormsModule,
    ReactiveFormsModule,
    DirectivesModule,
    PdfViewerModule,
  ]
})
export class CookingcenterpriodsModule { }
