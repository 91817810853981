import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from 'src/app/services/alert.service';
import { UserSessionService } from 'src/app/services/usersession.service';
import { PasswordStrengthValidator } from 'src/app/views/layout/validators/password-strength.validators';
import { UserService } from 'src/app/services/user.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import * as forge from 'node-forge';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.scss']
})
export class ChangepasswordComponent implements OnInit {
  form: FormGroup;
  returnUrl: string;
  abcd: number;
  eventData: any;
  userId: number;
  show: boolean;
  show2: boolean;
  showold: boolean;
  captchacode: string;

  publicKey: string = `-----BEGIN PUBLIC KEY-----
  MIIBITANBgkqhkiG9w0BAQEFAAOCAQ4AMIIBCQKCAQBkFOIsW99dBFw03fwW3fI5
  ihzoepYLnoq6uL4DzYGzi9QBYelHeQ3c5OxhfyPTqXfOJsselKuSM8vECSMy2Yy2
  z9hGM7OUTmUeJnCnu/C8zdQIfUXvmxchHmpbS++XwtV4ndaV22fhZL3uTESirv73
  WNwrGNg+ygFTH72PZ+5SayIMY2Vr5XJ0ca55mT+asr9rh5HbNGglKEOkBfaM4e4i
  tt2o+CVsZLkWcSGgrUqAOwh61DEdp54X31dpuYS1M1yTiY5jeABGseztALXev6FN
  FBQUdi6z/2YiBeY594PlvHmXvBk2pe+mLLVbacBeB7tFIqqVzf9KL4M1LumHuZyF
  AgMBAAE=
  -----END PUBLIC KEY-----`;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private authService: AuthenticationService,
    private userService: UserService,
    private alertService: AlertService,
    public dialogRef: MatDialogRef<ChangepasswordComponent>,
    private userSessionService: UserSessionService) {
    if (this.data) {
      this.eventData = JSON.stringify(data);
    }
    this.userId = this.userSessionService.userId();
    this.showold = false;
    this.show = false;
    this.show2 = false;
  }

  ngOnInit() {
    this.initializeValidators();
    this.OnCaptachaValidators();
  }

  initializeValidators() {
    this.form = new FormGroup({
      oldPassword: new FormControl('', [Validators.required]),
      newPassword: new FormControl('', [Validators.required, PasswordStrengthValidator]),
      confirmpassword: new FormControl('', [Validators.required]),
      txtCaptcha: new FormControl('', [Validators.required]),
      CaptchaDiv: new FormControl(this.captchacode, [Validators.required]),
      userId: new FormControl(this.userId, [Validators.required]),
    });
  }

  OnCaptachaValidators() {
    this.captchaGenerate();
    this.form.controls['CaptchaDiv'].setValue(this.captchacode);
    this.form.controls['txtCaptcha'].setValue(null);
  }

  captchaGenerate() {
    var alpha = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z');
    var i;
    for (i = 0; i < 6; i++) {
      var a = alpha[Math.floor(Math.random() * alpha.length)];
      var b = alpha[Math.floor(Math.random() * alpha.length)];
      var c = alpha[Math.floor(Math.random() * alpha.length)];
      var d = alpha[Math.floor(Math.random() * alpha.length)];
      var e = alpha[Math.floor(Math.random() * alpha.length)];
      var f = alpha[Math.floor(Math.random() * alpha.length)];
      var g = alpha[Math.floor(Math.random() * alpha.length)];
    }
    this.captchacode = a + b + c + d + e + f + g;
  }

  onSave() {
    this.showold = false;
    this.show = false;
    this.show2 = false;
    var rsa = forge.pki.publicKeyFromPem(this.publicKey);
    var newencryptedPassword = window.btoa(rsa.encrypt(this.form.value.newPassword));
    var oldencryptedPassword = window.btoa(rsa.encrypt(this.form.value.oldPassword));

    // this.form.controls['newPassword'].setValue(newencryptedPassword);
    // this.form.controls['oldPassword'].setValue(oldencryptedPassword)
    if (this.form.valid) {
      if (this.captchacode === this.form.controls['txtCaptcha'].value) {
        if (this.form.controls['newPassword'].value === this.form.controls['confirmpassword'].value) {
          var data={
            'userId':this.form.value.userId,
            'oldPassword':oldencryptedPassword,
            'newPassword':newencryptedPassword
          }
          this.userService.changepasswordUser(data).subscribe(result => {
            if (result && result.isSuccess) {
              this.alertService.success('Password has been Updated Successfully');
              this.dialogRef.close(result);
            } else {
              if (result && result.failures) {
                this.alertService.error(result.failures.toString());
              }
            }
          });
        }
      } else {
        this.alertService.error('Please enter the vaild captcha');
      }
    } else {
      this.validateFormControl();
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  password() {
    this.show = !this.show;
  }

  confirmpassword() {
    this.show2 = !this.show2;
  }

  oldPassword() {
    this.showold = !this.showold;
  }

  validateFormControl() {
    Object.keys(this.form.controls).forEach(field => {
      const control = this.form.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({
          onlySelf: true
        });
      }
    })
  }
}
